<template>
    <div>
        <div class="home">
            <img src="../../image/home.jpg" alt="">
        </div>
        <div class="beian" @click="clickBeian">
            <a href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020230727104400000010891733-SAIC_SHOW_310000-e57dc88eb5b040ee832e06c9dbb0f6a4144&signData=MEUCIHuMV7jYs2mdfDhVOf1EKYNsgy0VKaN8XZpvlNiR7Tw2AiEAlADmLj+DC9yqyRt10AZRU8GXF2uUkWtTj1brDbtckR4=">
                <img style="width: 30px;background-color: #ECEDEF;margin-right: 5px;" src="../../image/lz2.jpg" alt="">
            </a>
            <text class="one1">© 星矢数海版权所有 {{(new Date()).getFullYear()}}</text>
            <a class="one2" href="https://beian.miit.gov.cn/">备案号： 沪ICP备2022022180号-1</a> 
            <a class="one3" style="display: flex;justify-content: center;align-items: center;" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019455">
                <img src="/0.jpg" style="margin-right: 5px;"/>
                沪公网安备 31011502019455号
            </a>   
            <!-- <a target="_blank" href="https://beian.miit.gov.cn/"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">沪公网安备 31011502019455号</p ></a > -->
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'Home',
    methods: {
        clickBeian() {

        }
    }
};
</script>

<style scoped>
@media screen and (max-width: 500px) {
   .one1,.one2,.one3 {
    font-size: 12px !important;
   }
   .one1 {
    padding-right: 12px !important
   }
   .one3,.one2 {
    padding-left: 12px !important;
   }
}   
    .home {
        width: 100%;
        position: relative;
    }
    .home>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .beian {
        width: 100%;
        height: 50px;
        display: block;
        background-color: #ECEDEF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .beian>text {
        font-size: 18px;
        font-family:"Microsoft YaHei";
        padding-right: 30px;
        border-right: 2px solid #333;
        color: #333;
        font-weight: 500;
    }

    .beian>a {
        padding-left: 30px;
        text-decoration: none;
        color: #3378b5;
        font-weight: 500;
    }
</style>
  