<template>
  <Home/>
</template>

<script>
import Home from './views/home/home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
  html body {
    padding: 0px;
    margin: 0px;
  }
</style>
